import React from "react"
import Layout from "../components/Layout"
import Button from "../components/Button"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/dark-mode.scss"

import { FacebookShareButton, TwitterShareButton } from "react-share"

export default ({ location, pageContext: { title, body, relationships } }) => {
  const imageDetail =
    relationships?.field_image?.relationships?.field_media_image?.featuredImage

  return (
    <Layout fluid={true} pageInfo={{ pageName: "FP Community Detail" }}>
      <SEO title={title} keywords={[`community`, `first responders`]} />
      <Container className="content mb-5">
        <Row>
          <Col lg={2}>
            <Button
              to={`/first-responders/community`}
              icon={`arrow-left`}
              className={`back btn btn-red-outline`}
              variant={`red-outline`}
              label={`Back`}
            />
          </Col>
          <Col lg={8}>
            <Img
              className="img-featured rounded"
              key={
                "img-featured-" + imageDetail.childImageSharp.fixed.originalName
              }
              loading={"lazy"}
              fixed={imageDetail.childImageSharp.fixed}
              alt={title}
            />
          </Col>
        </Row>
        <Row className={"d-flex justify-content-center"}>
          <Col lg={6}>
            <Container className="content-text pt-5">
              <div className="title">{title}</div>
              <div className="share-this pt-5 pb-5">
                <div className="share-this social-links">Share </div>
                <FacebookShareButton quote={title} url={location.href}>
                  <FontAwesomeIcon color="#C02032" icon={faFacebookF} />
                </FacebookShareButton>
                <TwitterShareButton title={title} url={location.href}>
                  <FontAwesomeIcon color="#C02032" icon={faTwitter} />
                </TwitterShareButton>
              </div>
              <div dangerouslySetInnerHTML={{ __html: body.value }} />
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
